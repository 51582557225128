var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.jwt
    ? _c(
        "div",
        {
          staticClass: "d-flex align-items-center",
          attrs: { id: "current-user" },
        },
        [
          _c("notification-drop-down"),
          _c("span", { staticClass: "nav-divider" }),
          _c(
            "b-dropdown",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "button-content",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex ml-2" },
                          [
                            _c("b-avatar"),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column align-items-start ml-3",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#38455D",
                                      "font-size": "14px",
                                      "font-weight": "500",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.user_full_name))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#929BAA",
                                      "font-size": "12px",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.user_role_names))]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1586658907
              ),
            },
            [
              _c(
                "b-dropdown-item",
                { on: { click: _vm.logout } },
                [_c("b-icon-box-arrow-right"), _vm._v("Log Out")],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }