var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: { id: "notification-drop-down", tabindex: "0" },
      on: { focusout: _vm.hideNotifications },
    },
    [
      _c(
        "b-iconstack",
        {
          directives: [
            {
              name: "b-hover",
              rawName: "v-b-hover",
              value: _vm.handleIconHover,
              expression: "handleIconHover",
            },
          ],
          staticClass: "mr-3 cursor-pointer",
          on: { click: _vm.toggleNotifications },
        },
        [
          _c("b-icon", {
            style:
              _vm.isIconHovered || _vm.showNotifications
                ? "color:#DDE4F5"
                : "color:transparent",
            attrs: { icon: "circle-fill", scale: "2.3" },
          }),
          _c("b-icon", {
            style:
              _vm.isIconHovered || _vm.showNotifications
                ? "color:var(--blue)"
                : "color:#38455d",
            attrs: { icon: "bell", scale: "1.1" },
          }),
          _vm.hasUnreadNotifs
            ? _c("b-icon", {
                staticStyle: { color: "#fd4739" },
                attrs: {
                  icon: "circle-fill",
                  "shift-h": "4",
                  "shift-v": "4",
                  scale: "0.5",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.showNotifications
        ? _c(
            "b-card",
            { staticClass: "notification-card" },
            [
              _c("div", { staticClass: "notification-card-head" }, [
                _c(
                  "div",
                  { staticStyle: { color: "#010C14", "font-weight": "500" } },
                  [_vm._v("Notifications")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "avo-text-light cursor-pointer",
                    on: { click: _vm.markAllRead },
                  },
                  [_vm._v("Mark all as read")]
                ),
              ]),
              _vm._l(_vm.notifications, function (notification) {
                return _c(
                  "div",
                  {
                    key: notification.notification_id,
                    staticClass: "notification-row",
                    style:
                      _vm.isNotifHovered == notification.notification_id
                        ? "background-color:#F2F6FC"
                        : "background-color:white",
                    on: {
                      mouseover: function ($event) {
                        return _vm.handleNotifHover(
                          notification.notification_id
                        )
                      },
                      mouseleave: function ($event) {
                        return _vm.handleNotifHover(null)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          "notification-info d-flex justify-content-center" +
                          (_vm.notificationIsClickable(notification)
                            ? " cursor-pointer"
                            : ""),
                        on: {
                          click: function ($event) {
                            return _vm.handleNotificationClick(notification)
                          },
                        },
                      },
                      [
                        _c("b-avatar", { staticClass: "mr-2" }, [
                          notification.source_initials
                            ? _c("span", [
                                _vm._v(_vm._s(notification.source_initials)),
                              ])
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-start align-items-start",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  "font-weight": "500",
                                  color: "var(--dark)",
                                },
                              },
                              [_vm._v(_vm._s(notification.description))]
                            ),
                            _c("div", { staticClass: "avo-text-light-small" }, [
                              _vm._v(
                                _vm._s(
                                  notification.occurred_at
                                    ? _vm.formatDatetime(
                                        notification.occurred_at
                                      )
                                    : "N/A"
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c(
                          "b-iconstack",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.bottom",
                                value: notification.opened
                                  ? "Mark as unread"
                                  : "Mark as read",
                                expression:
                                  "notification.opened ? 'Mark as unread' : 'Mark as read'",
                                modifiers: { hover: true, bottom: true },
                              },
                            ],
                            staticClass: "mr-2 cursor-pointer",
                            on: {
                              mouseover: function ($event) {
                                return _vm.handleReadHover(
                                  notification.notification_id
                                )
                              },
                              mouseleave: function ($event) {
                                return _vm.handleReadHover(null)
                              },
                              click: function ($event) {
                                return _vm.toggleReadStatus(
                                  notification.notification_id,
                                  notification.opened
                                )
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              style:
                                _vm.readHover == notification.notification_id
                                  ? "color:#BBC9EC"
                                  : "color:#DDE4F5",
                              attrs: { icon: "circle-fill", scale: "1.25" },
                            }),
                            !notification.opened
                              ? _c("b-icon", {
                                  staticStyle: { color: "var(--blue)" },
                                  attrs: { icon: "circle-fill", scale: "0.75" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "b-iconstack",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.bottom",
                                value: "Clear notification",
                                expression: "'Clear notification'",
                                modifiers: { hover: true, bottom: true },
                              },
                            ],
                            staticClass: "mr-2 cursor-pointer",
                            on: {
                              mouseover: function ($event) {
                                return _vm.handleClearHover(
                                  notification.notification_id
                                )
                              },
                              mouseleave: function ($event) {
                                return _vm.handleClearHover(null)
                              },
                              click: function ($event) {
                                return _vm.clearNotification(
                                  notification.notification_id
                                )
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              style:
                                _vm.clearHover == notification.notification_id
                                  ? "color:#DDE4F5"
                                  : "color:transparent",
                              attrs: { icon: "circle-fill", scale: "1.25" },
                            }),
                            _c("b-icon", {
                              style:
                                _vm.clearHover == notification.notification_id
                                  ? "color:var(--dark)"
                                  : "color:var(--gray-800)",
                              attrs: { icon: "x", scale: "1.35" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm.notifications.length == 0
                ? _c("div", [
                    _c("div", { staticClass: "no-notifications-row" }, [
                      _vm._v(" You have no notifications at this time. "),
                    ]),
                  ])
                : _vm._e(),
              _c("div", {
                staticClass: "notification-card-foot cursor-pointer",
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }